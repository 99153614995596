export const plainComparison = (a, b) =>{
  if(a < b) return -1
  if(b < a) return 1
  return 0
}

export const generateItemSorter = (extractSortable) =>
  (a, b, column) => {
    const [attributeA, attributeB] = [extractSortable(a, column), extractSortable(b, column)]
    return plainComparison( attributeA, attributeB )
  }

export const numericPlainItemSorter = generateItemSorter((item, {attribute}) => parseFloat(item[attribute]))
export const stringPlainItemSorter  = generateItemSorter((item, {attribute}) => item.attributes[attribute].toLowerCase())
export const JSONAPIAttributeSorter = generateItemSorter((item, {attribute}) => item.attributes[attribute])
export const plainItemSorter        = generateItemSorter((item, {attribute}) => item[attribute])
export const taskCodeSorter         = generateItemSorter((item, {attribute}) => (parseFloat((item[attribute] || '').split('-').pop()) || 0))
